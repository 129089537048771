<div class="container-fluid main-container">
  <div class="row padder">
    <div class="col-md-4 pr-49 pl-0">
      <h1 class="heading-3 mb-vw font-semi-bold d-flex align-items-center justify-content-between">
        Top Players
        <!-- <select id="gender" class="form-control w-auto heading-3" [(ngModel)]="topPlayerNumbers"
          [ngModelOptions]="{ standalone: true }" (change)="getLeaderboard('all')" required>
          <option *ngFor="let count of countOptions" [ngValue]="count">{{count}}</option>
        </select> -->
      </h1>
      <div *ngFor="let player of players; let i = index" [class]="'card style-1 bg-' + (i+1)"
        [ngStyle]="{'background' : getColor(i, players.length)}">
        <div *ngIf="i < 3" class="num">
          <img src="/assets/img/crown.svg" alt="" class="mr-vw">
        </div>
        <div *ngIf="i >= 3" class="num mr-vw">
          {{ (i+1) < 10 ? '0' + (i+1) : i+1}} </div>
            <div class="name">
              <div class="info">
                <span [ngClass]="{'name-size-1' : i < 3,'name-size-3' : i >= 3}">{{player.name}}</span>
                <span>{{player?.email | lowercase}}</span>
                <!-- *ngIf="player?.email" -->
                <!-- <span class="desc" *ngIf="i === 0">KING</span>
            <span class="desc" *ngIf="i === 1">SUB-KING</span> -->
              </div>
            </div>
            <div class="score" [ngClass]="{'score-size-1' : i < 3, 'score-size-2' : i >= 3}">
              {{player.score | number:'1.0':'en-US'}}
            </div>
        </div>

        <div class="text-center" *ngIf="players?.length === 0">
          <img src="/assets/img/empty.svg" alt="trophy" class="empty_img" />
          <p class="empty_txt text-center">No Achievements Yet</p>
        </div>

        <div class="" *ngIf="!players">
          <div *ngFor="let player of loadArray; let i = index" [class]="'card style-1 bg-' + (i+1)"
            [ngStyle]="{'background' : getColor(i, defaultCount)}">
            <div *ngIf="i < 3" class="num loading">
              <img src="/assets/img/crown.svg" alt="" class="mr-vw">
            </div>
            <div *ngIf="i >= 3" class="num mr-vw loading">
              {{ (i+1) < 10 ? '0' + (i+1) : i+1}} </div>
                <div class="name">
                  <div class="info">
                    <span [ngClass]="{'name-size-1' : i < 3,'name-size-3' : i >= 3}">
                      <div class="rect loading"></div>
                    </span>
                    <span class="desc" *ngIf="i === 0">
                      <div class="small-rect loading"></div>
                    </span>
                    <span class="desc" *ngIf="i === 1">
                      <div class="small-rect loading"></div>
                    </span>
                  </div>
                </div>
                <div class="score loading" [ngClass]="{'score-size-1' : i < 3, 'score-size-2' : i >= 3}">
                  ???
                </div>
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="card active-style col-8 flex-row align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="/assets/img/active.svg" alt="" class="mr-vw">
              <h2 class="heading">Active Players</h2>
            </div>
            <span class="num" *ngIf="activePlayers">{{activePlayers | number:'1.0':'en-US'}}</span>
            <span class="num loading" *ngIf="!activePlayers">????</span>
          </div>

          <div class="col-12 col-md-8 p-0">
            <hr class="active-splitter">
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="header-card">
                <h3 class="d-inline heading">Weekly Leaderboard</h3>
                <span class="date d-flex align-items-center justify-content-between">{{
                  moment().startOf('week').format('DD MMM') }}
                  -
                  {{ moment().startOf('week').add(6, 'days').format('DD MMM') }}
                  <!-- <select id="gender" class="form-control w-auto date" [(ngModel)]="topWeeklyPlayerNumbers"
                [ngModelOptions]="{ standalone: true }" (change)="getLeaderboard('week')" required>
                <option *ngFor="let count of countOptions" [ngValue]="count">{{count}}</option>
              </select> -->
                </span>
              </div>

              <div class="card style-3" *ngFor="let player of weeklyLeader; let weekly_index = index"
                [ngClass]="{'noBorder' : weekly_index === (weeklyLeader.length - 1)}">

                <div class="flex-col w-100">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <span [class]="'circle-num bg-num-day-' + (weekly_index + 1)">
                        <p>{{weekly_index + 1}}</p>
                      </span>
                      <div class="flex-col">
                        <span class="name" [ngbTooltip]="player.name" placement="right"
                          [triggers]="isPhone ? 'click' : 'click:blur hover focus'">{{ player.name | uppercase }}</span>
                      </div>
                    </div>
                    <div class="challenges" *ngIf="player?.challenges?.length <= 5">
                      <div class="challenge position-relative"
                        *ngFor="let challenge of player.challenges; let iii = index">
                        <img [src]="challenge.pic ? challenge.pic : '/assets/img/crown.svg'" alt="" class=""
                          [ngbTooltip]="challenge.name" placement="top"
                          [triggers]="isPhone ? 'click' : 'click:blur hover focus'"
                          [ngClass]="{'mr-0' : (player.challenges.length - 1) === iii }" />
                        <span *ngIf="challenge.achievedNumber > 1">{{challenge.achievedNumber}}</span>
                      </div>
                    </div>
                    <div class="challenges" *ngIf="player?.challenges?.length > 5">
                      <div class="challenge position-relative"
                        *ngFor="let challenge of player.challenges; let ii = index" [ngClass]="{'noDisplay' : ii >= 4}">
                        <img [src]="challenge.pic ? challenge.pic : '/assets/img/crown.svg'" alt="" class=""
                          [ngbTooltip]="challenge.name" placement="top"
                          [triggers]="isPhone ? 'click' : 'click:blur hover focus'" />
                        <span *ngIf="challenge.achievedNumber > 1">{{challenge.achievedNumber}}</span>
                      </div>
                      <div class="extra" [ngbTooltip]="getExtraChallenges(player.challenges)" placement="left"
                        [triggers]="isPhone ? 'click' : 'click:blur hover focus'" tooltipClass="extraToolTip">
                        {{"+" + (player.challenges.length - 4)}}
                      </div>
                    </div>
                  </div>
                  <span class="email">{{player?.email}}</span>
                </div>
              </div>

              <div class="text-center" *ngIf="weeklyLeader?.length === 0">
                <img src="/assets/img/empty.svg" alt="trophy" class="empty_img" />
                <p class="empty_txt text-center">No Achievements Yet</p>
              </div>

              <div class="" *ngIf="!weeklyLeader">
                <div class="card style-3" *ngFor="let player of loadArray; let weekly_index = index"
                  [ngClass]="{'noBorder' : weekly_index === (10 - 1)}">
                  <div class="d-flex align-items-center">
                    <span [class]="'loading circle-num bg-num-day-' + (weekly_index + 1)">
                      <p>{{weekly_index + 1}}</p>
                    </span>
                    <span class="name">
                      <div class="rect loading"></div>
                    </span>
                  </div>
                  <div class="challenges">
                    <div class="challenge position-relative loading"
                      *ngFor="let challenge of ['','','','','','','','','']; let ii = index"
                      [ngClass]="{'noDisplay' : ii >= 4}">
                      <div class="square"></div>
                      <span>?</span>
                    </div>
                    <div class="extra loading">
                      ??
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-6 pl-vw">
              <div class="header-card">
                <h3 class="d-inline heading">Daily Leaderboard</h3>
                <span class="date d-flex align-items-center justify-content-between">{{ moment().format('DD MMM') }}
                  <!-- <select id="gender" class="form-control w-auto date" [(ngModel)]="topDailyPlayerNumbers"
                [ngModelOptions]="{ standalone: true }" (change)="getLeaderboard('day')" required>
                <option *ngFor="let count of countOptions" [ngValue]="count">{{count}}</option>
              </select> -->
                </span>
              </div>

              <div class="card style-3" *ngFor="let player of dailyLeader; let weekly_index = index"
                [ngClass]="{'noBorder' : weekly_index === (dailyLeader.length - 1)}">
                <div class="flex-col w-100">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <span [class]="'circle-num bg-num-week-' + (weekly_index + 1)">
                        <p>{{weekly_index + 1}}</p>
                      </span>
                      <div class="flex-col">
                        <span class="name" [ngbTooltip]="player.name" placement="right"
                          [triggers]="isPhone ? 'click' : 'click:blur hover focus'">{{player.name | uppercase}}</span>
                      </div>
                    </div>
                    <div class="challenges" *ngIf="player?.challenges?.length <= 5">
                      <div class="challenge position-relative"
                        *ngFor="let challenge of player.challenges; let iii = index" [ngbTooltip]="challenge.name"
                        placement="top" [triggers]="isPhone ? 'click' : 'click:blur hover focus'">
                        <img [src]="challenge.pic ? challenge.pic : '/assets/img/crown.svg'" alt="" class=""
                          [pTooltip]="challenge.name" tooltipPosition="top"
                          [ngClass]="{'mr-0' : (player.challenges.length - 1) === iii }" />
                        <span *ngIf="challenge.achievedNumber > 1">{{challenge.achievedNumber}}</span>
                      </div>
                    </div>
                    <div class="challenges" *ngIf="player?.challenges?.length > 5">
                      <div class="challenge position-relative"
                        *ngFor="let challenge of player.challenges; let ii = index" [ngbTooltip]="challenge.name"
                        placement="top" [triggers]="isPhone ? 'click' : 'click:blur hover focus'"
                        [ngClass]="{'noDisplay' : ii >= 4}">
                        <img [src]="challenge.pic ? challenge.pic : '/assets/img/crown.svg'" alt="" class=""
                          [pTooltip]="challenge.name" tooltipPosition="top" />
                        <span *ngIf="challenge.achievedNumber > 1">{{challenge.achievedNumber}}</span>
                      </div>
                      <div class="extra" [ngbTooltip]="getExtraChallenges(player.challenges)" placement="left"
                        [triggers]="isPhone ? 'click' : 'click:blur hover focus'" tooltipClass="extraToolTip">
                        {{"+" + (player.challenges.length - 4)}}
                      </div>
                    </div>
                  </div>
                  <span class="email">{{player?.email}}</span>
                </div>
              </div>

              <div class="text-center" *ngIf="dailyLeader?.length === 0">
                <img src="/assets/img/empty.svg" alt="trophy" class="empty_img" />
                <p class="empty_txt text-center">No Achievements Yet</p>
              </div>

              <div class="" *ngIf="!dailyLeader">
                <div class="card style-3" *ngFor="let player of loadArray; let weekly_index = index"
                  [ngClass]="{'noBorder' : weekly_index === (10 - 1)}">
                  <div class="d-flex align-items-center">
                    <span [class]="'loading circle-num bg-num-week-' + (weekly_index + 1)">
                      <p>{{weekly_index + 1}}</p>
                    </span>
                    <span class="name">
                      <div class="rect loading"></div>
                    </span>
                  </div>
                  <div class="challenges">
                    <div class="challenge position-relative loading"
                      *ngFor="let challenge of ['','','','','','','','','']; let ii = index"
                      [ngClass]="{'noDisplay' : ii >= 4}">
                      <div class="square"></div>
                      <span>?</span>
                    </div>
                    <div class="extra loading">
                      ??
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>© 2021 <a href="https://www.gameball.co/" target="_blank"><b>Gameball</b></a>. All rights reserved.</p>
    </div>