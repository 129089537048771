import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-top',
  templateUrl: './to-top.component.html',
  styleUrls: ['./to-top.component.css']
})
export class ToTopComponent implements OnInit {
  @HostListener("window:scroll", ["$event"]) onScroll(event) {
    let pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    let percentage = (pos - max) * 100 / max;

    if (percentage > 10) {
      this.showGoUp = true;
    } else {
      this.showGoUp = false;
    }

    if (document.documentElement.scrollTop < 100) {
      this.showGoUp = false;
    }
  }
  showGoUp: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  scrollUp() {
    window.scroll(0, 0);
  }
}
