import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeaderboardService {
  constructor(private http: HttpClient) { }

  // 847f97e90fcc477d85a8cb7670d89037 -- test
  // 27ce153348a64b0f961df6c4e016991d -- prod
  // 03cea843ad2940f5a2d51cd99c3f324e -- new
  getLeaderBoardPlayers(from: string, to: string, topCount: number) {
    const url = `${environment.prodApiUrl}/Leaderboard`;
    const requestheaders = new HttpHeaders().set(
      'apiKey',
      '03cea843ad2940f5a2d51cd99c3f324e'
    );
    let params = new HttpParams()
      .set('topCount', topCount.toString());

    if(from) params = params.set('from', from);
    if(to) params = params.set('to', to);
    
    return this.http.get<any[]>(url, { params, headers: requestheaders });
  }

  getChallengesInfo() {
    const url = `${environment.prodApiUrl}/Summary`;
    const requestheaders = new HttpHeaders().set(
      'apiKey',
      '03cea843ad2940f5a2d51cd99c3f324e'
    );
    return this.http.get<any>(url, { headers: requestheaders });
  }
}
