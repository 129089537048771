<div class="main p-4 p-md-5">
  <div class="row">
    <div class="col-12 col-md-4">
      <p>Ramadan Top Players (PSP)</p>
      <div *ngFor="let player of players; let i = index" class="card p-4 mb-4">
        <div class="d-flex align-items-center justify-content-between king" *ngIf="i === 0">
          <div class="d-flex  align-items-center">
            <img src="" alt="" class="" />
            <div class="flex-col">
              <p class="title">{{player.name}}</p>
              <!-- <p class="subTitle">KING</p> -->
            </div>
          </div>
          <p>{{player.score}}</p>
        </div>
        <div class="d-flex align-items-center justify-content-between subKing" *ngIf="i === 1">
          <div class="d-flex  align-items-center">
            <p class="rank mr-3">{{i+1}}</p>
            <div class="flex-col">
              <p class="title">{{player.name}}</p>
              <!-- <p class="subTitle">SUB-KING</p> -->
            </div>
          </div>
          <p>{{player.score}}</p>
        </div>
        <div class="d-flex align-items-center justify-content-between rest" *ngIf="i > 1">
          <div class="d-flex  align-items-center">
            <p class="rank mr-3">{{i+1}}</p>
            <p class="title">{{player.name}}</p>
          </div>
          <p>{{player.score}}</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-8">
      <p>right part</p>
    </div>
  </div>
</div>
