import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css'],
})
export class LeaderboardComponent implements OnInit {
  players: any[] = [
    {
      name: 'TAREK SHALABY',
      score: 1422,
    },
    {
      name: 'MOHSEN MOMTAZ',
      score: 1422,
    },
    {
      name: 'ADHAM SABRY',
      score: 1233,
    },
    {
      name: 'AHMED QADRY',
      score: 1223,
    },
    {
      name: 'RIHAM RAMZY',
      score: 1129,
    },
    {
      name: 'TAREK MOHSEN',
      score: 970,
    },
    {
      name: 'TAREK MOHSEN',
      score: 970,
    },
    {
      name: 'AHMED KHALED',
      score: 930,
    },
    {
      name: 'AHMED KHALED',
      score: 930,
    },
    {
      name: 'AHMED KHALED',
      score: 930,
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
