<!-- <app-leaderboard></app-leaderboard> -->
<div *ngIf="isIE()" class="ie_alert">
  <img src="assets/img/ie.png" alt="Internet Explorer" />
  <div class="alert">
    <p><b>Sorry, we no longer support Internet Explorer</b></p>
    <p>Please upgrade to Microsoft Edge, Google Chrome, or Firefox for better experience.</p>
  </div>
</div>
<app-leaderboard-main></app-leaderboard-main>
<app-to-top></app-to-top>
