import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LeaderboardService } from 'src/app/services/leaderboard.service';

@Component({
  selector: 'app-leaderboard-main',
  templateUrl: './leaderboard-main.component.html',
  styleUrls: ['./leaderboard-main.component.css'],
})
export class LeaderboardMainComponent implements OnInit, OnDestroy {
  defaultCount: number = 1000;
  defaultCountLoading: number = 200;
  loadArray: any[] = new Array(this.defaultCountLoading)
  moment = moment;
  players: any[];
  activePlayers: string;
  weeklyLeader: any[];
  dailyLeader: any[];
  interval: any;

  countOptions: number[] = [5, 10, 15, 20];

  topPlayerNumbers: number = this.defaultCount;
  topDailyPlayerNumbers: number = this.defaultCount;
  topWeeklyPlayerNumbers: number = this.defaultCount;

  challengesData: any[] = [];

  isPhone: boolean = false;

  colorOptions: string[] = ['333366', 'ffcc33', '669999', '4a7176', '434142'];

  constructor(private leaderboardService: LeaderboardService) { }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  ngOnInit(): void {
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.isPhone = true;
    }

    this.getLeaderBoardsData();
    this.interval = setInterval(() => {
      this.getLeaderBoardsData();
    }, 60000);

    // for (let i = 0; i < 20; i++) {
    //   this.getColor(i, 20)
    // }
  }

  getColor(index, leaderCount) {
    if (index < 3) {
      return `transparent linear-gradient(259deg, #${this.colorOptions[1]} 0%, #${this.colorOptions[1] + 'bb'} 100%) 0% 0% no-repeat padding-box`
    } else {
      index -= 3;
      const colorCount = this.colorOptions.length;
      const count = leaderCount / colorCount;
      const relativeIndex = index % count;
      const colorIndex = Math.floor(index / count);
      const color1 = this.colorOptions[colorIndex];
      const color2 = this.colorOptions[colorIndex + 1 > (this.colorOptions.length - 1) ? colorIndex : colorIndex + 1];
      const ratio = (count - relativeIndex) / count;

      const hex = function (x) {
        x = x.toString(16);
        return (x.length == 1) ? '0' + x : x;
      };

      const r = Math.ceil(parseInt(color1.substring(0, 2), 16) * ratio + parseInt(color2.substring(0, 2), 16) * (1 - ratio));
      const g = Math.ceil(parseInt(color1.substring(2, 4), 16) * ratio + parseInt(color2.substring(2, 4), 16) * (1 - ratio));
      const b = Math.ceil(parseInt(color1.substring(4, 6), 16) * ratio + parseInt(color2.substring(4, 6), 16) * (1 - ratio));

      const middle = hex(r) + hex(g) + hex(b);
      return `transparent linear-gradient(259deg, #${middle} 0%, #${middle + 'bb'} 100%) 0% 0% no-repeat padding-box`
    }
  }

  getLeaderBoardsData() {
    this.activePlayers = null;
    this.getLeaderboard('all');
    this.getChallenges().subscribe(
      (res) => {
        this.activePlayers = res.playersCount;
        this.challengesData = res.challenges;
        this.getLeaderboard('day');
        this.getLeaderboard('week');
      },
      (err) => {
        this.activePlayers = '0';
        this.challengesData = [];
        this.getLeaderboard('day');
        this.getLeaderboard('week');
      }
    );
  }

  getChallenges() {
    return this.leaderboardService.getChallengesInfo();
  }

  getLeaderboard(type: string) {
    switch (type) {
      case 'day':
        this.dailyLeader = null;
        const firstOfDay = moment().startOf('day').format("yyyy-MM-DD HH:mm:ss");
        const lastOfDay = moment().endOf('day').format("yyyy-MM-DD HH:mm:ss");

        this.leaderboardService
          .getLeaderBoardPlayers(
            firstOfDay,
            lastOfDay,
            this.topDailyPlayerNumbers
          )
          .subscribe(
            (res) => {
              this.dailyLeader = res.map((el) => ({
                email: el.email,
                // .replace("ewedypsp.com", ".."),
                name: el.displayName,
                challenges: [...el.achievements].map((ele) => {
                  const challenge = this.challengesData.filter(
                    (ch) => ch.id === ele.challengeId
                  );
                  const challengeData =
                    challenge && challenge.length > 0 ? challenge[0] : null;
                  return {
                    pic: challengeData ? challengeData.iconPath : null,
                    name: challengeData ? (challengeData.challengeBehaviourLocals && challengeData.challengeBehaviourLocals.length > 0 && challengeData.challengeBehaviourLocals[0].gameName ? challengeData.challengeBehaviourLocals[0].gameName : challengeData.name) : null,
                    achievedNumber: ele.achievementCount,
                  };
                }),
              }));
            },
            (err) => {
              this.dailyLeader = [];
            }
          );
        break;

      case 'week':
        this.weeklyLeader = null;
        const firstWeekDay = moment().startOf('week').format("yyyy-MM-DD HH:mm:ss");
        const lastWeekDay = moment()
          .startOf('week')
          .add(6, 'days')
          .endOf('day')
          .format("yyyy-MM-DD HH:mm:ss");
        this.leaderboardService
          .getLeaderBoardPlayers(
            firstWeekDay,
            lastWeekDay,
            this.topWeeklyPlayerNumbers
          )
          .subscribe(
            (res) => {
              this.weeklyLeader = res.map((el) => ({
                email: el.email,
                // .replace("ewedypsp.com", ".."),
                name: el.displayName,
                challenges: [...el.achievements].map((ele) => {
                  const challenge = this.challengesData.filter(
                    (ch) => ch.id === ele.challengeId
                  );
                  const challengeData =
                    challenge && challenge.length > 0 ? challenge[0] : null;
                  return {
                    pic: challengeData ? challengeData.iconPath : null,
                    name: challengeData ? (challengeData.challengeBehaviourLocals && challengeData.challengeBehaviourLocals.length > 0 && challengeData.challengeBehaviourLocals[0].gameName ? challengeData.challengeBehaviourLocals[0].gameName : challengeData.name) : null,
                    achievedNumber: ele.achievementCount,
                  };
                }),
              }));
            },
            (err) => {
              this.weeklyLeader = [];
            }
          );
        break;

      case 'all':
        this.players = null;
        this.leaderboardService
          .getLeaderBoardPlayers(null, null, this.topPlayerNumbers)
          .subscribe(
            (res) => {
              this.players = res.map((el) => ({
                email: el.email,
                name: el.displayName,
                score: el.score,
              }));
            },
            (err) => {
              this.players = [];
            }
          );
        break;

      default:
        this.players = null;
        this.leaderboardService
          .getLeaderBoardPlayers(null, null, this.topPlayerNumbers)
          .subscribe(
            (res) => {
              this.players = res.map((el) => ({
                email: el.email,
                name: el.displayName,
                score: el.score,
              }));
            },
            (err) => {
              this.players = [];
            }
          );
        break;
    }
  }

  getExtraChallenges(challenges) {
    let tooltip = '';
    const c = [...challenges].forEach((el, index) => {
      // console.log(el);
      if (index >= 4) {
        tooltip += `${el.name ? el.name : 'Not Provided'}  (${el.achievedNumber
          }) \n`;
      }
    });
    return tooltip;
  }
}
